/* global OPEN_ATHENS_AUTH_URL */
import React from 'react'

import './styles.css'

function OpenAthensSignIn() {
  return (
    <a href={OPEN_ATHENS_AUTH_URL} className="btn -wide" id="OpenAthensSignIn">
      <div className="internal-container">
        <img
          src="/global/assets/images/logo/OpenAthens-green-icon-300x300.png"
          alt="OpenAthens logo"
        />
        Continue with OpenAthens
      </div>
    </a>
  )
}

export default OpenAthensSignIn
