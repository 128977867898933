export default plan => {
  switch (plan.toLowerCase().replace(/ /g, '-')) {
    case 'basic-monthly':
      return 'icon-monster-1'
    case 'monthly':
      return 'icon-monster-2'
    case 'yearly':
      return 'icon-monster-3'
    case 'group-learning':
      return 'icon-monster-2 repeat-3'
    default:
      return ''
  }
}
