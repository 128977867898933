import * as React from 'react';
import * as _ from 'lodash';
import Helmet from 'react-helmet';
import BlogPosts from '../Blog/BlogPosts';
import Tutorial from '../generic/CourseSummary';
import Testimonial from '../generic/Testimonial/index.jsx';
import BannerSlideShow from '../generic/BannerSlideShow';
import MoreButton from '../generic/MoreButton';
import StaticCategories from './StaticCategories';
import CourseListPlaceholder from './CourseListPlaceholder';
import CurrentlyWatching from './CurrentlyWatching';
import RecentPathways from './RecentPathways';
import SubscribeDialogCTA from '../App/SubscribeDialogCTA';
import { homeStatus } from '../../redux/modules/home';
import { Link } from 'react-router';
import { JSONLD } from 'react-structured-data';
import { Generic } from 'react-structured-data';
import LeadMagnetFunnelForm from '../Tutorials/TutorialDetail/LeadMagnetFunnelForm';
export default function () {
    function repeatMeta1(props, isSubscribed, usedFreeTrial, homeIsReady, meta, metaIndex) {
        return React.createElement('title', { 'key': metaIndex }, meta.value);
    }
    function repeatMeta2(props, isSubscribed, usedFreeTrial, homeIsReady, meta, metaIndex) {
        return React.createElement('meta', {
            'key': metaIndex,
            'name': meta.name,
            'content': meta.value
        });
    }
    function repeatFbImage3(props, isSubscribed, usedFreeTrial, homeIsReady, fbImage, fbImageIndex) {
        return React.createElement('meta', {
            'property': 'og:image',
            'content': fbImage.original,
            'key': fbImageIndex
        });
    }
    function repeatTutorial4(props, isSubscribed, usedFreeTrial, homeIsReady, tutorial, tutorialIndex) {
        return React.createElement(Tutorial, {
            'className': 'tutorial',
            'key': 'lastest-tutorial-' + tutorial.id,
            'slug': tutorial.slug,
            'summary': tutorial.description,
            'teacher': tutorial.artist.title,
            'teacherSlug': tutorial.artist.slug,
            'title': tutorial.title,
            'thumb': tutorial.photo.boxThumb,
            'new': tutorial.new
        });
    }
    function repeatTutorial5(props, isSubscribed, usedFreeTrial, homeIsReady, tutorial, tutorialIndex) {
        return React.createElement(Tutorial, {
            'className': 'tutorial',
            'key': tutorial.id,
            'slug': tutorial.slug,
            'summary': tutorial.description,
            'teacher': tutorial.artist.title,
            'teacherSlug': tutorial.artist.slug,
            'title': tutorial.title,
            'thumb': tutorial.photo.boxThumb,
            'new': tutorial.new
        });
    }
    function repeatCategory6(props, isSubscribed, usedFreeTrial, homeIsReady, featuredCategory, category, categoryIndex) {
        return React.createElement(Link, {
            'className': 'category',
            'to': this.getCategoryURL(category),
            'key': category.id,
            'aria-labelledby': 'popular-category-' + category.name.toLowerCase().trim().replace(' ', '-')
        }, React.createElement('div', { 'className': 'category-info' }, React.createElement('img', {
            'alt': 'Popular category ' + category.name + ' image',
            'src': category.image.default
        }), React.createElement('div', { 'className': 'overlay' }), React.createElement('h3', { 'id': 'popular-category-' + category.name.toLowerCase().trim().replace(' ', '-') }, '\n                ', category.name, '\n              ')));
    }
    function repeatCategory7(props, isSubscribed, usedFreeTrial, homeIsReady, featuredCategory, category, categoryIndex) {
        return React.createElement(Link, {
            'className': 'category',
            'to': this.getCategoryURL(category),
            'key': category.id,
            'aria-labelledby': 'popular-category-' + category.name.toLowerCase().trim().replace(' ', '-')
        }, React.createElement('div', { 'className': 'category-info' }, React.createElement('img', {
            'alt': 'Popular category ' + category.name + ' image',
            'src': category.image.default
        }), React.createElement('div', { 'className': 'overlay' }), React.createElement('h3', { 'id': 'popular-category-' + category.name.toLowerCase().trim().replace(' ', '-') }, '\n              ', category.name, '\n            ')));
    }
    function scopeFeaturedCategory8(props, isSubscribed, usedFreeTrial, homeIsReady) {
        var featuredCategory = this.props.categoryImages[2];
        return React.createElement.apply(this, [
            'div',
            { 'className': 'categories' },
            React.createElement.apply(this, [
                'div',
                { 'className': 'category-group' },
                _.map(this.props.categoryImages.slice(0, 2), repeatCategory6.bind(this, props, isSubscribed, usedFreeTrial, homeIsReady, featuredCategory))
            ]),
            React.createElement(Link, {
                'className': 'category big',
                'to': this.getCategoryURL(featuredCategory),
                'aria-labelledby': 'popular-category-' + featuredCategory.name.toLowerCase().trim().replace(' ', '-')
            }, React.createElement('div', { 'className': 'category-info' }, React.createElement('img', {
                'alt': 'Popular category ' + featuredCategory.name + ' image',
                'src': featuredCategory.image.featured
            }), React.createElement('div', { 'className': 'overlay' }), React.createElement('h3', { 'id': 'popular-category-' + featuredCategory.name.toLowerCase().trim().replace(' ', '-') }, '\n              ', featuredCategory.name, '\n            '))),
            _.map(this.props.categoryImages.slice(3), repeatCategory7.bind(this, props, isSubscribed, usedFreeTrial, homeIsReady, featuredCategory))
        ]);
    }
    function repeatCategory9(props, isSubscribed, usedFreeTrial, homeIsReady, splitValue, category, categoryIndex) {
        return React.createElement(Link, {
            'className': 'category',
            'to': this.getCategoryURL(category),
            'key': category.id,
            'aria-labelledby': 'popular-category-' + category.name.toLowerCase().trim().replace(' ', '-')
        }, React.createElement('div', { 'className': 'category-info' }, React.createElement('img', {
            'alt': 'Popular category ' + category.name + ' image',
            'src': category.image.default
        }), React.createElement('div', { 'className': 'overlay' }), React.createElement('h3', { 'id': 'popular-category-' + category.name.toLowerCase().trim().replace(' ', '-') }, '\n                ', category.name, '\n              ')));
    }
    function repeatCategory10(props, isSubscribed, usedFreeTrial, homeIsReady, splitValue, category, categoryIndex) {
        return React.createElement(Link, {
            'className': 'category',
            'to': this.getCategoryURL(category),
            'key': category.id,
            'aria-labelledby': 'popular-category-' + category.name.toLowerCase().trim().replace(' ', '-')
        }, React.createElement('div', { 'className': 'category-info' }, React.createElement('img', {
            'alt': 'Popular category ' + category.name + ' image',
            'src': category.image.default
        }), React.createElement('div', { 'className': 'overlay' }), React.createElement('h3', { 'id': 'popular-category-' + category.name.toLowerCase().trim().replace(' ', '-') }, '\n                ', category.name, '\n              ')));
    }
    function scopeSplitValue11(props, isSubscribed, usedFreeTrial, homeIsReady) {
        var splitValue = Math.floor(_.size(this.props.categoryImages) / 2);
        return React.createElement('div', {
            'className': 'wrapper-categories',
            'key': '58023'
        }, React.createElement('h2', { 'className': 'wrapper' }, 'Popular Categories'), React.createElement('div', { 'className': 'categories' }, React.createElement.apply(this, [
            'div',
            { 'className': 'category-group' },
            _.map(this.props.categoryImages.slice(0, splitValue), repeatCategory9.bind(this, props, isSubscribed, usedFreeTrial, homeIsReady, splitValue))
        ]), React.createElement.apply(this, [
            'div',
            { 'className': 'category-group' },
            _.map(this.props.categoryImages.slice(splitValue), repeatCategory10.bind(this, props, isSubscribed, usedFreeTrial, homeIsReady, splitValue))
        ])));
    }
    function scopePropsIsSubscribedUsedFreeTrialHomeIsReady12() {
        var props = this.props;
        var isSubscribed = this.props.hasSubscription;
        var usedFreeTrial = this.props.usedFreeTrial;
        var homeIsReady = this.props.status === homeStatus.success;
        return React.createElement('div', { 'id': 'Home' }, React.createElement.apply(this, [
            Helmet,
            {},
            _.map(_.filter(props.metaInformation, m => m.name === 'title'), repeatMeta1.bind(this, props, isSubscribed, usedFreeTrial, homeIsReady)),
            _.map(_.filter(props.metaInformation, m => m.name !== 'title'), repeatMeta2.bind(this, props, isSubscribed, usedFreeTrial, homeIsReady)),
            props.fbImages.length ? _.map(props.fbImages, repeatFbImage3.bind(this, props, isSubscribed, usedFreeTrial, homeIsReady)) : null
        ]), React.createElement('div', { 'className': 'banner-placeholder' }, homeIsReady ? [
            _.size(props.banners) === 0 && props.bannerStatus ? React.createElement('div', {
                'className': 'subscribe-banner',
                'style': this.getBackground(),
                'key': '18601'
            }, !isSubscribed ? React.createElement('div', {
                'className': 'tagline big',
                'key': '2031'
            }, React.createElement('h3', {}, props.freeTrialBanner.topTitle ? props.freeTrialBanner.topTitle : 'Join the Premier Online Community')) : null, React.createElement('h1', {
                'className': _.transform({ 'is-subscribed': isSubscribed }, function (res, value, key) {
                    if (value) {
                        res.push(key);
                    }
                }, []).join(' ')
            }, '\n          ', this.getMainTitle(), '\n          '), isSubscribed ? React.createElement('div', {
                'className': 'tagline is-subscribed',
                'key': '2339'
            }, React.createElement('h2', {}, props.freeTrialBanner.mainSubscribeTagline ? props.freeTrialBanner.mainSubscribeTagline : 'Online Courses with Hollywood\'s Master FX Artists')) : null, !isSubscribed && !usedFreeTrial ? React.createElement('h3', { 'key': '2588' }, props.freeTrialBanner.middleTitle ? props.freeTrialBanner.middleTitle : 'free 7-Day Trial') : null, !isSubscribed ? React.createElement(SubscribeDialogCTA, { 'key': '2741' }, React.createElement('div', {
                'className': 'btn -orange',
                'style': { marginTop: '24px' }
            }, !usedFreeTrial ? [
                '\n                ',
                props.freeTrialBanner.ctaFreeTrial ? props.freeTrialBanner.ctaFreeTrial : 'Start My Free Trial',
                '\n              '
            ] : null, usedFreeTrial ? [
                '\n                ',
                props.freeTrialBanner.ctaSubscribe ? props.freeTrialBanner.ctaSubscribe : 'Start subscription',
                '\n              '
            ] : null)) : null, isSubscribed ? React.createElement(Link, {
                'to': '/tutorials',
                'className': 'btn -orange',
                'key': '3292'
            }, 'View Courses') : null, props.freeTrialBanner.bottomTitle ? React.createElement('h3', {
                'className': 'new-courses',
                'key': '3385'
            }, props.freeTrialBanner.bottomTitle) : null) : null,
            _.size(props.banners) > 0 ? React.createElement(BannerSlideShow, {
                'banners': props.banners,
                'isMobile': this.props.isMobile,
                'key': '18603'
            }) : null
        ] : null), this.props.latestTutorials && this.props.latestTutorials.length ? React.createElement('div', {
            'className': 'tutorials wrapper',
            'key': '3697'
        }, React.createElement('h2', { 'className': 'tutorials__title' }, 'Latest Courses'), React.createElement.apply(this, [
            'div',
            { 'className': 'list' },
            _.map(this.props.latestTutorials, repeatTutorial4.bind(this, props, isSubscribed, usedFreeTrial, homeIsReady))
        ]), React.createElement(MoreButton, { 'url': '/tutorials' })) : null, React.createElement('div', { 'className': 'testimonial-holder' }, React.createElement('div', { 'className': 'overlay' }), React.createElement('div', { 'className': 'smaller-wrapper' }, React.createElement(Testimonial, {}))), !this.props.latestTutorials || !this.props.latestTutorials.length ? React.createElement(CourseListPlaceholder, {
            'title': 'Latest Courses',
            'key': '4549'
        }) : null, _.size(props.currentlyWatching.results) > 0 ? React.createElement('div', {
            'className': 'currently-watching',
            'key': '4684'
        }, React.createElement(CurrentlyWatching, {
            'courses': props.currentlyWatching,
            'loadMore': props.loadMoreCurrent
        })) : null, _.size(props.recentsPaths.results) > 0 ? React.createElement('div', {
            'className': 'recents-paths-section',
            'key': '4876'
        }, React.createElement(RecentPathways, {
            'pathways': props.recentsPaths,
            'loadMore': props.loadMoreRecentPathways
        })) : null, this.props.tutorials.length ? React.createElement('div', {
            'className': 'tutorials wrapper',
            'key': '5068'
        }, React.createElement('h2', { 'className': 'tutorials__title' }, 'Featured Courses'), React.createElement.apply(this, [
            'div',
            { 'className': 'list' },
            _.map(this.props.tutorials, repeatTutorial5.bind(this, props, isSubscribed, usedFreeTrial, homeIsReady))
        ]), React.createElement(MoreButton, { 'url': '/tutorials' })) : null, !this.props.tutorials.length ? React.createElement(CourseListPlaceholder, {
            'title': 'Featured Courses',
            'key': '5701'
        }) : null, _.size(this.props.categoryImages) > 5 ? [
            !this.props.isMobile ? React.createElement('div', {
                'className': 'wrapper-categories wrapper',
                'key': '58021'
            }, React.createElement('h2', {}, 'Popular Categories'), scopeFeaturedCategory8.apply(this, [
                props,
                isSubscribed,
                usedFreeTrial,
                homeIsReady
            ])) : null,
            this.props.isMobile ? scopeSplitValue11.apply(this, [
                props,
                isSubscribed,
                usedFreeTrial,
                homeIsReady
            ]) : null
        ] : null, _.size(this.props.categoryImages) < 6 ? [React.createElement(StaticCategories, {
                'isMobile': props.isMobile,
                'key': '100671'
            })] : null, _.size(props.secondaryBanners) > 0 ? React.createElement(BannerSlideShow, {
            'banners': props.secondaryBanners,
            'isMobile': this.props.isMobile,
            'isSecondary': true,
            'key': '10197'
        }) : null, _.size(props.secondaryBanners) === 0 ? React.createElement('div', {
            'className': 'banner-slideshow-placeholder',
            'key': '10398'
        }) : null, React.createElement('div', { 'className': 'blog-posts wrapper' }, React.createElement('h2', {}, 'Latest Blog Posts'), React.createElement(BlogPosts, {
            'blogPosts': this.props.blogPosts,
            'showSocial': false
        }), React.createElement(MoreButton, { 'url': '/blog' })), !props.isAuth ? React.createElement('div', {
            'className': 'funnel-home',
            'key': '10678'
        }, React.createElement(LeadMagnetFunnelForm, { 'tag': 'Homepage Email Lead' })) : null, React.createElement(JSONLD, {}, React.createElement(Generic, {
            'type': 'organization',
            'jsonldtype': 'Organization',
            'schema': {
                url: 'https://www.stanwinstonschool.com',
                logo: `${ ASSETS_PATH }global/assets/images/logo/sw-logo-schema.png`,
                sameAs: [
                    'https://www.facebook.com/StanWinstonSchool',
                    'https://www.instagram.com/stanwinstonschool/',
                    'https://www.youtube.com/stanwinstonschool',
                    'https://www.pinterest.com/stanwinston/',
                    'https://plus.google.com/+StanWinstonSchool/'
                ]
            }
        })));
    }
    return scopePropsIsSubscribedUsedFreeTrialHomeIsReady12.apply(this, []);
}