import React from 'react'

import { Link } from 'react-router'
import OALogo from '../../global/assets/images/logo/OA-logo.png'

import './styles.css'

function OpenAthensSSOError() {
  return (
    <div id="OpenAthensSSOError" className="wrapper">
      <div className="container">
        <img className="oa-logo" src={OALogo} alt="open athens logo" />
        <h1>Access denied</h1>
        <p>
          The provider of this resource has denied you access. For assistance
          please contact your organisation administrator.
        </p>
        <Link to="/">Return Home</Link>
      </div>
    </div>
  )
}

export default OpenAthensSSOError
