import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import getPlanIcon from '../../utils/getPlanIcon';
export default function () {
    function scopePlanName1(plans, globalPromo, isGlobalPromoEnabled, plan, planIndex, isProrated) {
        var planName = plan.name.toLowerCase();
        return React.createElement('span', { 'className': 'price' }, '\n            $', this.getDiscountedPrice(plan), '\n            ', planName === 'yearly' || planName === 'annual' ? React.createElement('span', { 'key': '951' }, '/Yr') : null, planName === 'monthly' ? React.createElement('span', { 'key': '1035' }, '/Mo') : null);
    }
    function scopePlanName2(plans, globalPromo, isGlobalPromoEnabled, plan, planIndex, isProrated) {
        var planName = plan.name.toLowerCase();
        return React.createElement('span', {
            'className': 'price-before',
            'key': '1111'
        }, '\n            $', plan.price, '\n            ', planName === 'yearly' || planName === 'annual' ? React.createElement('span', { 'key': '1255' }, '/Yr') : null, planName === 'monthly' ? React.createElement('span', { 'key': '1339' }, '/Mo') : null);
    }
    function onClick3(plans, globalPromo, isGlobalPromoEnabled, plan, planIndex, isProrated) {
        this.props.selectPlan(plan);
    }
    function onClick4(plans, globalPromo, isGlobalPromoEnabled, plan, planIndex, isProrated) {
        this.props.selectPlan(plan);
    }
    function onClick5(plans, globalPromo, isGlobalPromoEnabled, plan, planIndex, isProrated) {
        this.props.selectPlan(plan);
    }
    function scopeSaving6(plans, globalPromo, isGlobalPromoEnabled, plan, planIndex, isProrated) {
        var saving = (plan.price - plan.firstMonthPrice).toFixed(2);
        return React.createElement('span', {
            'className': 'upgrade-plan',
            'key': '3850'
        }, 'Save $', saving, ' on your first payment');
    }
    function scopeIsProrated7(plans, globalPromo, isGlobalPromoEnabled, plan, planIndex) {
        var isProrated = !(_.size(_.filter(plans, p => p.firstMonthPrice !== null)) === 0);
        return React.createElement('div', {
            'key': plan.id,
            'className': 'col plan' + ' ' + _.transform({ 'no-group-plan': this.props.isMobile && plan.rank === 4 }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, React.createElement('div', {
            'className': 'row header' + ' ' + _.transform({ 'special-plan': plan.rank === 3 }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, React.createElement('i', { 'className': getPlanIcon(plan.name) }), React.createElement('span', { 'className': 'plan-name' }, plan.name), scopePlanName1.apply(this, [
            plans,
            globalPromo,
            isGlobalPromoEnabled,
            plan,
            planIndex,
            isProrated
        ]), isGlobalPromoEnabled ? scopePlanName2.apply(this, [
            plans,
            globalPromo,
            isGlobalPromoEnabled,
            plan,
            planIndex,
            isProrated
        ]) : null), React.createElement('div', { 'className': 'row current-plan' }, this.props.currentPlan === plan.rank && this.props.upgrading && plan.rank <= this.props.currentPlan ? React.createElement('span', {
            'className': 'current-plan',
            'key': '1469'
        }, 'current plan') : null), React.createElement('div', {
            'className': 'row cta' + ' ' + _.transform({ 'simple-plan': plan.rank < 3 }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, !isGlobalPromoEnabled && ((this.props.currentPlan || !this.props.upgrading) && this.props.currentPlan !== plan.rank && plan.rank > 0 && this.props.currentPlan < 3) ? [
            plan.rank > this.props.currentPlan && this.props.upgrading ? React.createElement('button', {
                'className': 'btn -thin -border-white',
                'onClick': this.props.upgrading ? () => this.props.upgradeAction(this.props.plans, plan, this.props.currentPlan) : () => this.props.selectPlan(plan),
                'key': '17931'
            }, this.props.isSubscribed ? 'upgrade' : 'subscribe') : null,
            !this.props.upgrading && this.props.usedFreeTrial ? React.createElement('button', {
                'className': 'btn -thin' + ' ' + _.transform({
                    '-orange': plan.rank === 3,
                    '-border-white': plan.rank < 3
                }, function (res, value, key) {
                    if (value) {
                        res.push(key);
                    }
                }, []).join(' '),
                'onClick': onClick3.bind(this, plans, globalPromo, isGlobalPromoEnabled, plan, planIndex, isProrated),
                'key': '17933'
            }, '\n                Subscribe\n              ') : null,
            !this.props.upgrading && !this.props.usedFreeTrial ? React.createElement('button', {
                'className': 'btn -thin' + ' ' + _.transform({
                    '-orange': plan.rank === 3,
                    '-border-white': plan.rank < 3
                }, function (res, value, key) {
                    if (value) {
                        res.push(key);
                    }
                }, []).join(' '),
                'onClick': onClick4.bind(this, plans, globalPromo, isGlobalPromoEnabled, plan, planIndex, isProrated),
                'key': '17935'
            }, '\n                7-Day Free Trial\n              ') : null
        ] : null, isGlobalPromoEnabled && ((this.props.currentPlan || !this.props.upgrading) && this.props.currentPlan !== plan.rank && plan.rank > 0 && this.props.currentPlan < 3) ? [!this.props.upgrading && !this.props.usedFreeTrial ? React.createElement('button', {
                'className': 'btn -thin' + ' ' + _.transform({
                    '-orange': plan.rank === 3,
                    '-border-white': plan.rank < 3
                }, function (res, value, key) {
                    if (value) {
                        res.push(key);
                    }
                }, []).join(' '),
                'onClick': onClick5.bind(this, plans, globalPromo, isGlobalPromoEnabled, plan, planIndex, isProrated),
                'key': '31291'
            }, '\n                Subscribe & save ', globalPromo.discount, globalPromo.discountType == 'pctg' ? ['%'] : null) : null] : null), React.createElement('div', { 'className': 'row savings' }, plan.rank > this.props.currentPlan && plan.firstMonthPrice && isProrated && plan.price - plan.firstMonthPrice > 0 ? scopeSaving6.apply(this, [
            plans,
            globalPromo,
            isGlobalPromoEnabled,
            plan,
            planIndex,
            isProrated
        ]) : null));
    }
    function repeatPlan8(plans, globalPromo, isGlobalPromoEnabled, plan, planIndex, isProrated) {
        return scopeIsProrated7.apply(this, [
            plans,
            globalPromo,
            isGlobalPromoEnabled,
            plan,
            planIndex
        ]);
    }
    function repeatPlan9(plans, globalPromo, isGlobalPromoEnabled, plan, planIndex) {
        return React.createElement('div', {
            'className': 'checkbox',
            'key': plan.id
        }, plan.name === 'Monthly' ? React.createElement('span', {
            'className': 'text',
            'key': '5136'
        }, '\n            Unlimited\n          ') : null, plan.name === 'Yearly' ? React.createElement('span', {
            'className': 'text',
            'key': '5238'
        }, '\n            Unlimited\n          ') : null);
    }
    function scopePlansGlobalPromoIsGlobalPromoEnabled10() {
        var plans = this.props.plans.filter(this.filterSubscriptionPlansMobile);
        var globalPromo = this.props.globalPromo;
        var isGlobalPromoEnabled = this.props.isGlobalPromoEnabled;
        return React.createElement('section', { 'id': 'SubscriptionPlanListMobile' }, React.createElement.apply(this, [
            'div',
            { 'className': 'sticky' },
            _.map(plans, repeatPlan8.bind(this, plans, globalPromo, isGlobalPromoEnabled))
        ]), React.createElement('div', { 'className': 'col large main-table' }, !isGlobalPromoEnabled ? React.createElement('div', {
            'className': 'row',
            'key': '4240'
        }, React.createElement('span', { 'className': 'large-description' }, 'Save $320 per year (compared with the Monthly plan)'), React.createElement('div', { 'className': 'checkboxes' }, React.createElement('div', { 'className': 'checkbox' }), React.createElement('div', { 'className': 'checkbox' }, React.createElement('i', { 'className': 'icon-checkmark' })))) : null, !isGlobalPromoEnabled ? React.createElement('div', {
            'className': 'row',
            'key': '4576'
        }, React.createElement('span', { 'className': 'large-description' }, 'First 7 days are free'), React.createElement('div', { 'className': 'checkboxes' }, React.createElement('div', { 'className': 'checkbox' }, React.createElement('i', { 'className': 'icon-checkmark' })), React.createElement('div', { 'className': 'checkbox' }, React.createElement('i', { 'className': 'icon-checkmark' })))) : null, React.createElement('div', { 'className': 'row' }, React.createElement('span', { 'className': 'large-description' }, 'Access our On-Demand Course library'), React.createElement.apply(this, [
            'div',
            { 'className': 'checkboxes' },
            _.map(plans, repeatPlan9.bind(this, plans, globalPromo, isGlobalPromoEnabled))
        ])), React.createElement('div', { 'className': 'row' }, React.createElement('span', { 'className': 'large-description' }, 'Accces to Pathways'), React.createElement('div', { 'className': 'checkboxes' }, React.createElement('div', { 'className': 'checkbox' }, React.createElement('i', { 'className': 'icon-checkmark' })), React.createElement('div', { 'className': 'checkbox' }, React.createElement('i', { 'className': 'icon-checkmark' })))), React.createElement('div', { 'className': 'row' }, React.createElement('span', { 'className': 'large-description' }, 'Watch on your computer, phone, or tablet'), React.createElement('div', { 'className': 'checkboxes' }, React.createElement('div', { 'className': 'checkbox' }, React.createElement('i', { 'className': 'icon-checkmark' })), React.createElement('div', { 'className': 'checkbox' }, React.createElement('i', { 'className': 'icon-checkmark' })))), !isGlobalPromoEnabled ? React.createElement('div', {
            'className': 'row',
            'key': '6026'
        }, React.createElement('span', { 'className': 'large-description' }, 'Live Course Discounts'), React.createElement('div', { 'className': 'checkboxes' }, React.createElement('div', { 'className': 'checkbox' }, React.createElement('span', { 'className': 'text' }, plans[0].description2)), React.createElement('div', { 'className': 'checkbox' }, !isGlobalPromoEnabled ? React.createElement('span', {
            'className': 'text',
            'key': '6319'
        }, 'FREE') : null, isGlobalPromoEnabled ? React.createElement('i', {
            'className': 'icon-checkmark',
            'key': '6390'
        }) : null))) : null, React.createElement('div', { 'className': 'row' }, React.createElement('span', { 'className': 'large-description' }, 'Discounts on DVD, Stream Purchases & Merchandise'), React.createElement('div', { 'className': 'checkboxes' }, React.createElement('div', { 'className': 'checkbox' }, React.createElement('span', { 'className': 'text' }, 'No')), React.createElement('div', { 'className': 'checkbox' }, !isGlobalPromoEnabled ? React.createElement('span', {
            'className': 'text',
            'key': '6766'
        }, plans[0].description2) : null, isGlobalPromoEnabled ? React.createElement('span', {
            'className': 'text',
            'key': '6855'
        }, React.createElement('div', { 'className': 'checkbox' }, React.createElement('i', { 'className': 'icon-checkmark' }))) : null)))));
    }
    return scopePlansGlobalPromoIsGlobalPromoEnabled10.apply(this, []);
}