import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { subscriptionPlanSelectors } from 'reduxModules/subscriptions/selectors'

import Collapsible from 'react-collapsible'

import { subscriptionPlansDispatcher as dispatchSelector } from 'reduxModules/subscriptions/dispatchers'
// import SubscriptionPlanBox from './SubscriptionPlanBox'
import PLANS from './fixturePlans'
import SubscriptionPlanList from './SubscriptionPlanList'
import Testimonial from '../generic/Testimonial/index'

import './styles.css'
import LoadingComponent from '../generic/LoadingComponent'

export function SubscriptionPlans({
  isGlobalPromoEnabled,
  globalPromo,
  usedFreeTrial,
  router,
  isMobile,
  getSubscriptionPlanLanguage,
  subscriptionPlansLanguage
}) {
  const {
    headlineTitle,
    subHeadline1,
    subHeadline2,
    discount,
    codename,
    isLoading
  } = globalPromo

  useEffect(() => {
    getSubscriptionPlanLanguage()
  }, [])

  return (
    <main id="SubscriptionPlans">
      {isLoading && <LoadingComponent />}
      {!isLoading && (
        <section className="hero">
          <div className="hero-image">
            <div className="overlay" />
            <div className="wrapper">
              <h1 className="headline">
                {isGlobalPromoEnabled && headlineTitle
                  ? headlineTitle
                  : subscriptionPlansLanguage?.headline}
              </h1>
              {!isGlobalPromoEnabled ? (
                <h2>{subscriptionPlansLanguage?.subheadline}</h2>
              ) : (
                <h2>
                  {subHeadline1 || `Save ${discount}% with code ${codename}`}
                </h2>
              )}
              {isGlobalPromoEnabled && (
                <h3>
                  {subHeadline2 ||
                    'Discount lasts as long as you remain a subscriber!'}
                </h3>
              )}
            </div>
          </div>
        </section>
      )}
      {/* <section className="plan-boxes wrapper">
        {plans.map(plan => (
          <SubscriptionPlanBox
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...plan}
            router={router}
            usedFreeTrial={usedFreeTrial}
            key={plan.id}
          />
        ))}
      </section> */}
      {!isLoading && (
        <div className="smaller-wrapper plans">
          <SubscriptionPlanList
            router={router}
            plans={PLANS}
            usedFreeTrial={usedFreeTrial}
            globalPromo={globalPromo}
          />
        </div>
      )}
      {isMobile ? (
        <div className="group-subscriptions-ad wrapper">
          <section>
            <h2 className="title-division">Group Learning</h2>
            <p>
              Looking for{' '}
              <a href="https://pages.stanwinstonschool.com/group-subscription-info-request-v2">
                Group Learning solutions
              </a>
              ?
            </p>
            <p>
              We offer subscription discounts for educational institutions and
              organizations with five (5) or more students, including
              customizable student access to our online library to fit a variety
              of curriculum and program goals.
              <br />
              <br />
              <a href="https://pages.stanwinstonschool.com/group-subscription-info-request-v2">
                {' '}
                Check out our Group Learning plans{' '}
              </a>
            </p>
          </section>
        </div>
      ) : null}
      <div className="testimonial-holder">
        <div className="overlay" />
        <div className="smaller-wrapper">
          <Testimonial />
        </div>
      </div>
      <section className="faq smaller-wrapper">
        <h2 className="title-division">Frequently Asked Questions</h2>
        <Collapsible
          transitionTime={300}
          easing="ease-in-out"
          trigger="What experience is necessary to enroll?"
        >
          <p>
            The Stan Winston School of Character Arts is open to anyone with a
            curious mind. No previous experience or education is required.
          </p>
        </Collapsible>
        <Collapsible
          transitionTime={300}
          easing="ease-in-out"
          trigger="Is there a minimum time that I have to be a subscriber?"
        >
          <p>
            There is no required amount of time you must stay a subscriber on
            our site. You have the option to cancel if you don&apos;t have
            sufficient time or funds to be a subscriber for the upcoming month.
          </p>
        </Collapsible>
        <Collapsible
          transitionTime={300}
          easing="ease-in-out"
          trigger="Are the on-demand courses available for download?"
        >
          <p>
            Subscriptions give you access to streaming video available on our
            site. These cannot be downloaded. If you want a physical copy of any
            course you can purchase the individual course on DVD.
          </p>
        </Collapsible>
        <Collapsible
          transitionTime={300}
          easing="ease-in-out"
          trigger="What forms of payment do you accept?"
        >
          <p>We accept PayPal and credit card payments.</p>
        </Collapsible>
        <Collapsible
          transitionTime={300}
          easing="ease-in-out"
          trigger="How long is the pay period?"
        >
          <p>
            Our billing cycle for monthly subscriptions is 30 days. For our
            Annual subscription you will be charged the full amount once a year.
          </p>
        </Collapsible>
        <Collapsible
          transitionTime={300}
          easing="ease-in-out"
          trigger="Is there an option not to auto-renew?"
        >
          <p>
            All of our subscriptions are on Automatic Renewal. You can see when
            the next pay period occurs on your ACCOUNT page, so you will know
            when you will be charged again if you do not cancel your
            subscription.
          </p>
        </Collapsible>
        <Collapsible
          transitionTime={300}
          easing="ease-in-out"
          trigger="When can I cancel my account so it won't auto-renew?"
        >
          <p>
            Once you receive the receipt for payment you are able to cancel your
            subscription and have access for the remaining time you have
            purchased.
          </p>
        </Collapsible>
        <Collapsible
          transitionTime={300}
          easing="ease-in-out"
          trigger="Do you offer subscription discounts?"
        >
          <p>
            Yes, we&apos;re happy to offer special discounts to full-time
            students and veterans of any nation with proof of eligibility. For
            more information, please contact us at info@stanwinstonschool.com.
          </p>
        </Collapsible>
        <Collapsible
          transitionTime={300}
          easing="ease-in-out"
          trigger="As a Basic Subscriber, how many times can I watch the four courses I select for the month?"
        >
          <p>
            Basic Subscribers can watch their four chosen courses as often as
            they want during the 30-day billing cycle. At the end of the month,
            the selected courses will deactivate so you can pick 4 new courses
            to view.
          </p>
        </Collapsible>
        <Collapsible
          transitionTime={300}
          easing="ease-in-out"
          trigger="What's the difference between a Live Course and an On-Demand course?"
        >
          <p>
            The Live Courses feed our On-Demand Course Library. Eventually, all
            Live Courses are released into the Course Library and are available
            for all of our subscribers to watch.
          </p>
          <p>
            We are currently on schedule to release one course into the Course
            Library per month.
          </p>
          <p>
            We understand that Live Courses can be expensive. But if you are an
            Annual subscriber you can watch the Live Courses with a free
            reserved seat. It makes the Annual Subscription a fantastic value.
          </p>
          <p>
            You can sign up for a Live Course if you do not want to wait for the
            release into the Course library to watch the course material. Also,
            the Live Courses are a lot of fun, you can interact with the
            instructors and other students, ask questions and receive feedback
            from the artist.
          </p>
        </Collapsible>
      </section>
    </main>
  )
}

SubscriptionPlans.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  usedFreeTrial: PropTypes.bool.isRequired,
  globalPromo: PropTypes.shape({
    codename: PropTypes.string,
    discount: PropTypes.number,
    discountType: PropTypes.string,
    plans: PropTypes.shape({
      id: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        price: PropTypes.number,
        discountAmount: PropTypes.number,
        priceDiscount: PropTypes.number
      })
    })
  })
}

SubscriptionPlans.defaultProps = {
  globalPromo: {
    codename: '',
    discount: 0,
    discountType: '',
    plans: {}
  }
}

export default connect(
  subscriptionPlanSelectors,
  dispatchSelector
)(SubscriptionPlans)
