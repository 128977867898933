import { initialize } from 'redux-form'

import {
  showNewPaymentMethodForm,
  savePaymentMethodFieldsFailure,
  hideNewPaymentMethodForm
} from 'reduxModules/forms/paymentMethod'
import {
  activateSubscribingMode,
  deactivateSubscribingMode,
  validateAddress,
  types as checkoutTypes
} from 'reduxModules/checkout'
import { clearDiscountCode } from 'reduxModules/discountCode'
import { initConfirmation } from 'reduxModules/confirmation'
import _ from 'lodash'
import modalDispatchers from 'reduxModules/generic/modal/dispatchers'
import {
  subscriptionPlansFetch,
  subscriptionsFetch
} from 'reduxModules/subscriptions'
import { clearRecaptchaToken } from 'reduxModules/app'
import {
  getUserProfile,
  getUserTutorials,
  getUserFavorites,
  updateUserProfile,
  createUserAddress,
  updateUserAddress,
  getUserAddresses,
  removeUserAddress,
  getUserPaymentMethods,
  markDefaultUserPaymentMethod,
  removePaymentMethod,
  getUserBraintreeToken,
  savePaymentMethod,
  savePaymentMethodFailure,
  editingAddress,
  types as userTypes,
  getUserOrders,
  getOrderDetail,
  showAddressFormAction,
  hideAddressFormAction,
  changePlan,
  getUserLearningPaths,
  closeProfile
} from './'
import notifications, {
  toastError,
  toastServerError,
  toastSuccess
} from '../../../global/notifications'

import fixturePlansLegacy from '../../../components/SubscriptionPlans/fixturePlansLegacy'

export const braintreeErrorHandler = (dispatch, error) => {
  if (error.code === 'HOSTED_FIELDS_FIELDS_INVALID')
    dispatch(savePaymentMethodFieldsFailure(error))
  else {
    dispatch(savePaymentMethodFailure(error))
    toastServerError(error.message)
  }
}

export const profileDispatchers = dispatch => ({
  loadUserProfile() {
    dispatch(getUserProfile())
  },
  onSubmit(data) {
    data = { ...data }
    Object.keys(data).forEach(key => {
      if (key.startsWith('movie')) {
        data[`movie_${key.substr(5)}`] = data[key] !== '' ? data[key] : null
        delete data[key]
      }
    })
    dispatch(updateUserProfile(data))
      .then(([_, res]) => {
        if (res.type === userTypes.USERS_PROFILE_UPDATE_SUCCESS) {
          toastSuccess(notifications.profileUpdate)
          dispatch(closeProfile())
        } else toastError(notifications.profileUpdate)
      })
      .catch(error => toastServerError(error.errors))
  },
  updateInterests(data) {
    dispatch(updateUserProfile(data))
      .then(([_, res]) => {
        if (res.type === userTypes.USERS_PROFILE_UPDATE_SUCCESS)
          toastSuccess(notifications.interestsUpdate)
        else toastError(notifications.interestsUpdate)
      })
      .catch(error => toastServerError(error.errors))
  }
})

export const tutorialsDispatchers = dispatch => ({
  loadUserTutorials() {
    dispatch(getUserTutorials())
  }
})

export const favoritesDispatchers = dispatch => ({
  loadFavorites() {
    dispatch(getUserFavorites())
  }
})

export const addressDispatchers = dispatch => ({
  onSubmit(data, dispatch, props) {
    import('reduxModules/utils/getCountryCode').then(getCountryCode => {
      const countryCodeAlpha2 = getCountryCode.default(data.countryName)
      const { editing, values } = props
      const newData = {
        ..._.omitBy(data, _.isNil),
        countryCodeAlpha2,
        dismissWeightValidation: true
      }
      dispatch(validateAddress({ ...newData, id: undefined }))
        .then(([_, res]) => {
          if (res.type === checkoutTypes.VALIDATE_ADDRESS_SUCCESS) {
            const action = editing
              ? updateUserAddress(values.id)(newData)
              : createUserAddress(newData)

            dispatch(action).then(([_, res]) => {
              if (res.type === userTypes.USERS_ADDRESS_CREATE_SUCCESS)
                toastSuccess(notifications.addressCreated)
              else if (res.type === userTypes.USERS_ADDRESS_UPDATE_SUCCESS)
                toastSuccess(notifications.addressUpdated)
              else if (
                res.type === userTypes.USERS_ADDRESS_CREATE_REQUEST_FAILURE
              )
                toastError(notifications.addressCreated)
              else if (
                res.type === userTypes.USERS_ADDRESS_UPDATE_REQUEST_FAILURE
              )
                toastError(notifications.addressUpdated)
              dispatch(initialize('createAddressForm'))
              dispatch(hideAddressFormAction())
            })
          }
        })
        .catch(payload => {
          toastServerError(payload.errors)
        })
    })
  },
  showAddressForm: () => dispatch(showAddressFormAction()),
  hideAddressForm: () => dispatch(hideAddressFormAction())
})

export const addressesDispatchers = dispatch => ({
  loadAddresses() {
    dispatch(getUserAddresses())
  },
  removeAddress(pk, activeAddressFormId) {
    const confirmation = {
      title: 'Warning',
      text: 'Are you sure you want to remove this address?',
      options: [
        { resolveValue: 'remove', text: 'Remove', class: 'confirm-btn' }
      ]
    }
    dispatch(initConfirmation(confirmation)).on('remove', () =>
      dispatch(removeUserAddress(pk)).then(([_, res]) => {
        if (res.type === userTypes.USERS_ADDRESS_REMOVE_SUCCESS) {
          toastSuccess(notifications.addressRemoved)
          if (pk === activeAddressFormId) {
            dispatch(initialize('createAddressForm'))
            dispatch(hideAddressFormAction())
          }
        } else toastError(notifications.addressRemoved)
      })
    )
  },
  editAddress(address) {
    dispatch(initialize('createAddressForm', address))
    dispatch(editingAddress())
  },
  ...modalDispatchers(dispatch)
})

export const dispatchGetUserBraintreeToken = dispatch =>
  dispatch(getUserBraintreeToken())

export const paymentMethodListDispatchers = dispatch => ({
  loadPaymentMethods() {
    dispatch(getUserPaymentMethods())
  },
  markPaymentDefault(token) {
    dispatch(markDefaultUserPaymentMethod(token)()).then(([_, res]) => {
      if (res.type === userTypes.USERS_MARK_PAYMENT_METHOD_DEFAULT_SUCCESS)
        toastSuccess(notifications.paymentMethodSetAsDefault)
      else toastError(notifications.paymentMethodSetAsDefault)
    })
  },
  markPaymentDefaultWithconfirmation(token) {
    const confirmation = {
      title: 'Warning',
      text: 'Your subscription will now be charged to this card',
      options: [
        { resolveValue: 'accept', text: 'Accept', class: 'confirm-btn' }
      ]
    }
    dispatch(initConfirmation(confirmation)).on('accept', () =>
      this.markPaymentDefault(token)
    )
  },
  removePaymentMethodDispatcher(token) {
    const confirmation = {
      title: 'Warning',
      text: 'Are you sure you want to remove this payment method?',
      options: [
        { resolveValue: 'remove', text: 'Remove', class: 'confirm-btn' }
      ]
    }
    dispatch(initConfirmation(confirmation)).on('remove', () =>
      dispatch(removePaymentMethod(token)).then(([_, res]) => {
        if (res.type === userTypes.USERS_PAYMENT_METHOD_REMOVE_SUCCESS)
          toastSuccess(notifications.paymentMethodDeleted)
        else toastError(notifications.paymentMethodDeleted)
      })
    )
  },
  ...modalDispatchers(dispatch)
})

export const dispatchSavePaymentMethod = (dispatch, payload, recaptcha) => {
  dispatch(savePaymentMethod(recaptcha)(payload))
    .then(([_, res]) => {
      if (res.type === userTypes.USERS_PAYMENT_METHOD_SAVE_SUCCESS) {
        toastSuccess(notifications.paymentMethodCreated)
        dispatch(hideNewPaymentMethodForm())
      } else toastError(notifications.paymentMethodCreated)
    })
    .catch(error => toastServerError(error.errors))
}

export const paymentMethodsDispatchers = (
  stateProps,
  dispatchProps,
  ownProps
) => {
  const { dispatch } = dispatchProps
  return {
    ...stateProps,
    ...ownProps,
    loadPaymentMethods() {
      dispatch(getUserPaymentMethods())
    },
    showNewPaymentMethodForm: () => dispatch(showNewPaymentMethodForm()),
    getBraintreeClientToken: () => {
      const { clientToken } = stateProps
      if (clientToken === '') dispatchGetUserBraintreeToken(dispatch)
    },
    savePaymentMethod(deviceData, tokenize, recaptcha) {
      tokenize()
        .then(payload => {
          dispatchSavePaymentMethod(
            dispatch,
            {
              deviceData,
              paymentMethodNonce: payload.nonce
            },
            recaptcha
          )
        })
        .catch(error => braintreeErrorHandler(dispatch, error))
        .finally(() => dispatch(clearRecaptchaToken()))
    },
    hideNewPaymentMethodForm: () => dispatch(hideNewPaymentMethodForm()),
    showFraudProtectionError() {
      toastError(notifications.fraudProtection)
    }
  }
}

export const ordersDispatchers = dispatch => ({
  loadOrders() {
    dispatch(getUserOrders())
  },
  loadSubscriptions() {
    dispatch(subscriptionsFetch())
  }
})

export const orderDetailDispatchers = dispatch => ({
  loadOrderDetail: pk => dispatch(getOrderDetail(pk)())
})

export const changeSubscriptionDispatchers = dispatch => ({
  dispatchUpgradePlan: (plans, newPlan, oldPlan) => {
    const isProrated = !(
      _.size(_.filter(fixturePlansLegacy, p => p.firstMonthPrice !== null)) ===
      0
    )

    const planInfo = _.find(fixturePlansLegacy, {
      rank: oldPlan
    })
    if (planInfo) {
      const confirmation = {
        title: 'Upgrade subscription plan',
        discountCode: true,
        options: [
          { resolveValue: 'accept', text: 'upgrade', class: 'confirm-btn' }
        ],
        height: 650,
        newPlan,
        planInfo,
        isProrated,
        cancelCallback: () => {
          dispatch(deactivateSubscribingMode())
          dispatch(clearDiscountCode())
        },
        componentId: 'upgrade'
      }
      dispatch(activateSubscribingMode(newPlan))
      dispatch(initConfirmation(confirmation)).on('accept', coupon => {
        const request = { newPlan: newPlan.id }
        if (coupon) request.coupon = coupon.codename
        return dispatch(changePlan(request))
          .then(([_, res]) => {
            if (res.type === userTypes.USERS_CHANGE_PLAN_SUCCESS) {
              toastSuccess(notifications.upgradeSubscription)
              dispatch(subscriptionPlansFetch())
              dispatch(clearDiscountCode())
            } else toastError(notifications.upgradeSubscription)
          })
          .catch(() => toastError(notifications.upgradeSubscription))
      })
    } else toastError(notifications.unupgradeableSubscription)
  },
  ...modalDispatchers(dispatch)
})

export const learningPathsDispatchers = dispatch => ({
  loadUserLearningPaths() {
    dispatch(getUserLearningPaths())
  }
})
