export default [
  {
    url: 'http://localhost:3000/api/v1/plans/3/',
    regularPrice: 59.99,
    id: 3,
    name: 'Monthly',
    trial: '8 days',
    description1: '<span class="accent">Unlimited Courses</span>',
    description2: '30% Off',
    checkoutDescription2: 'Live Course Discounts',
    description3: 'No Long-Term Commitment.',
    rank: 2,
    firstMonthPrice: null,
    price: 59.99,
    owned: false
  },
  {
    url: 'http://localhost:3000/api/v1/plans/4/',
    regularPrice: 399.99,
    id: 4,
    name: 'Yearly',
    trial: '8 days',
    description1: '<span class="accent">Unlimited Courses</span>',
    description2: '30% Off',
    checkoutDescription2:
      '30% off DVD, Stream Purchases & Merchandise + FREE Live Courses',
    description3: 'Save $320 per year (compared with the Monthly plan)',
    rank: 3,
    firstMonthPrice: null,
    price: 399.99,
    owned: false
  },
  {
    url: 'http://localhost:3000/api/v1/plans/4/',
    regularPrice: 359.94,
    id: 9,
    name: 'Group Learning',
    trial: '8 days',
    description1:
      '<span class="accent accent--curriculums">Customizable Curriculums</span>',
    description2: '30% Off',
    checkoutDescription2:
      '30% off DVD, Stream Purchases & Merchandise + FREE Live Courses',
    description3: 'Significant Savings!',
    rank: 4,
    firstMonthPrice: null,
    price: 0,
    owned: false
  }
]
