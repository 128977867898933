import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
export default function () {
    function repeatItem1(props, subscription, subscribed, freeSubscription, menuHeight, collapsed, item, itemIndex) {
        return React.createElement('li', { 'key': itemIndex }, React.createElement(Link, { 'to': item.url }, React.createElement('span', {
            'className': _.transform({ active: props.active === item.url }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, item.name)));
    }
    function repeatItem2(props, subscription, subscribed, freeSubscription, menuHeight, collapsed, item, itemIndex) {
        return React.createElement('li', { 'key': itemIndex }, React.createElement(Link, {
            'to': item.url,
            'onClick': () => this.toggleCollapsing()
        }, React.createElement('span', {
            'className': _.transform({ active: props.active === item.url || props.active === item.slug }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, item.name)));
    }
    function scopePropsSubscriptionSubscribedFreeSubscriptionMenuHeightCollapsed3() {
        var props = this.props;
        var subscription = props.subscription;
        var subscribed = !_.isNull(props.subscription);
        var freeSubscription = subscribed && subscription.free;
        var menuHeight = _.size(props.items) * 56;
        var collapsed = this.state.collapsed;
        return React.createElement('div', {
            'id': 'Sidebar',
            'style': { minHeight: (collapsed ? 65 : menuHeight + 45) + 'px' },
            'className': _.transform({ collapsed: collapsed }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, !props.isMobile ? React.createElement('div', {
            'className': 'sidebar-desktop',
            'key': '413'
        }, React.createElement('div', { 'className': 'sidebar-holder' }, React.createElement('div', { 'className': 'menu-header' }, React.createElement('div', {}, React.createElement('span', {}, props.name))), React.createElement.apply(this, [
            'ul',
            { 'className': 'menu-items' },
            _.map(props.items, repeatItem1.bind(this, props, subscription, subscribed, freeSubscription, menuHeight, collapsed))
        ]), React.createElement('div', { 'className': 'banner' }, React.createElement('div', { 'className': 'subscription-status' }, !freeSubscription ? React.createElement(Link, {
            'to': '/change_subscription',
            'key': '1083'
        }, React.createElement('span', {
            'className': _.transform({ active: props.active === '/change_subscription' }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, 'subscription')) : null, !freeSubscription ? React.createElement('span', {
            'className': _.transform({ accent: subscribed }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' '),
            'key': '1301'
        }, subscribed ? [props.subscription.plan] : null, !subscribed ? ['none'] : null) : null), props.active !== '/change_subscription' ? [(!subscribed || subscription.rank < 3) && !freeSubscription ? React.createElement(Link, {
                'to': '/change_subscription',
                'key': '16011'
            }, React.createElement('button', { 'className': 'subscribe-button btn -thin -orange' }, !subscribed ? 'Subscribe' : 'Upgrade')) : null] : null))) : null, props.isMobile ? React.createElement('div', {
            'className': 'topbar-mobile wrapper',
            'key': '2016'
        }, React.createElement('div', {
            'className': 'current-element',
            'onClick': () => this.toggleCollapsing()
        }, React.createElement('span', {}, props.name), React.createElement('div', { 'className': 'arrow' }, React.createElement('i', { 'className': 'icon-right-2' }))), React.createElement.apply(this, [
            'ul',
            {
                'className': 'menu-items',
                'style': { maxHeight: (collapsed ? 0 : props.height - 160) + 'px' }
            },
            _.map(props.items, repeatItem2.bind(this, props, subscription, subscribed, freeSubscription, menuHeight, collapsed))
        ])) : null);
    }
    return scopePropsSubscriptionSubscribedFreeSubscriptionMenuHeightCollapsed3.apply(this, []);
}